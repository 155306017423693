export const emojiList = [
  {
    name: '表情',
    label: 'face',
    value: [
      '😀',
      '😁',
      '😂',
      '🤣',
      '😃',
      '😄',
      '😅',
      '😆',
      '😉',
      '😊',
      '😋',
      '😎',
      '😍',
      '😘',
      '😗',
      '😙',
      '😚',
      '☺️',
      '🙂',
      '🤗',
      '🤩',
      '🤔',
      '🤨',
      '😐',
      '😑',
      '😶',
      '🙄',
      '😏',
      '😣',
      '😥',
      '😮',
      '🤐',
      '😯',
      '😪',
      '😫',
      '😴',
      '😌',
      '😛',
      '😜',
      '😝',
      '🤤',
      '😒',
      '😓',
      '😔',
      '😕',
      '🙃',
      '🤑',
      '😲',
      '☹️',
      '🙁',
      '😖',
      '😞',
      '😟',
      '😤',
      '😢',
      '😭',
      '😦',
      '😧',
      '😨',
      '😩',
      '🤯',
      '😬',
      '😰',
      '😱',
      '😳',
      '🤪',
      '😵',
      '😡',
      '😠',
      '🤬',
      '😷',
      '🤒',
      '🤕',
      '🤢',
      '🤮',
      '🤧',
      '😇',
      '🤠',
      '🤡',
      '🤥',
      '🤫',
      '🤭',
      '🧐',
      '🤓',
      '😈',
      '👿',
      '👹',
      '👺',
      '💀',
      '👻',
      '👽',
      '🤖',
      '💩',
      '😺',
      '😸',
      '😹',
      '😻',
      '😼',
      '😽',
      '🙀',
      '😿',
      '😾'
    ]
  },
  {
    name: '手势',
    label: 'guester',
    value: [
      '🤲',
      '👐',
      '🙌',
      '👏',
      '🤝',
      '👍',
      '👎',
      '👊',
      '✊',
      '🤛',
      '🤜',
      '🤞',
      '✌️',
      '🤟',
      '🤘',
      '👌',
      '👈',
      '👉',
      '👆',
      '👇',
      '☝️',
      '✋',
      '🤚',
      '🖐',
      '🖖',
      '👋',
      '🤙',
      '💪',
      '🖕',
      '✍️',
      '🙏'
    ]
  },
  {
    name: '人物',
    label: 'person',
    value: [
      '👶',
      '👧',
      '🧒',
      '👦',
      '👩',
      '🧑',
      '👨',
      '👵',
      '🧓',
      '👴',
      '👲',
      '👳‍♀️',
      '👳‍♂️',
      '🧕',
      '🧔',
      '👱‍♂️',
      '👱‍♀️',
      '👮‍♀️',
      '👮‍♂️',
      '👷‍♀️',
      '👷‍♂️',
      '💂‍♀️',
      '💂‍♂️',
      '🕵️‍♀️',
      '🕵️‍♂️',
      '👩‍⚕️',
      '👨‍⚕️',
      '👩‍🌾',
      '👨‍🌾',
      '👩‍🍳',
      '👨‍🍳',
      '👩‍🎓',
      '👨‍🎓',
      '👩‍🎤',
      '👨‍🎤',
      '👩‍🏫',
      '👨‍🏫',
      '👩‍🏭',
      '👨‍🏭',
      '👩‍💻',
      '👨‍💻',
      '👩‍💼',
      '👨‍💼',
      '👩‍🔧',
      '👨‍🔧',
      '👩‍🔬',
      '👨‍🔬',
      '👩‍🎨',
      '👨‍🎨',
      '👩‍🚒',
      '👨‍🚒',
      '👩‍✈️',
      '👨‍✈️',
      '👩‍🚀',
      '👨‍🚀',
      '👩‍⚖️',
      '👨‍⚖️',
      '👰',
      '🤵',
      '👸',
      '🤴',
      '🤶',
      '🎅',
      '🧙‍♀️',
      '🧙‍♂️',
      '🧝‍♀️',
      '🧝‍♂️',
      '🧛‍♀️',
      '🧛‍♂️',
      '🧟‍♀️',
      '🧟‍♂️',
      '🧞‍♀️',
      '🧞‍♂️',
      '🧜‍♀️',
      '🧜‍♂️',
      '🧚‍♀️',
      '🧚‍♂️',
      '👼',
      '🤰',
      '🤱',
      '🙇‍♀️',
      '🙇‍♂️',
      '💁‍♀️',
      '💁‍♂️',
      '🙅‍♀️',
      '🙅‍♂️',
      '🙆‍♀️',
      '🙆‍♂️',
      '🙋‍♀️',
      '🙋‍♂️',
      '🤦‍♀️',
      '🤦‍♂️',
      '🤷‍♀️',
      '🤷‍♂️',
      '🙎‍♀️',
      '🙎‍♂️',
      '🙍‍♀️',
      '🙍‍♂️',
      '💇‍♀️',
      '💇‍♂️',
      '💆‍♀️',
      '💆‍♂️',
      '🧖‍♀️',
      '🧖‍♂️',
      '💅',
      '🤳',
      '💃',
      '🕺',
      '👯‍♀️',
      '👯‍♂️',
      '🕴',
      '🚶‍♀️',
      '🚶‍♂️',
      '🏃‍♀️',
      '🏃‍♂️',
      '👫',
      '👭',
      '👬',
      '💑',
      '👩‍❤️‍👩',
      '👨‍❤️‍👨',
      '💏',
      '👩‍❤️‍💋‍👩',
      '👨‍❤️‍💋‍👨',
      '👪',
      '👨‍👩‍👧',
      '👨‍👩‍👧‍👦',
      '👨‍👩‍👦‍👦',
      '👨‍👩‍👧‍👧',
      '👩‍👩‍👦',
      '👩‍👩‍👧',
      '👩‍👩‍👧‍👦',
      '👩‍👩‍👦‍👦',
      '👩‍👩‍👧‍👧',
      '👨‍👨‍👦',
      '👨‍👨‍👧',
      '👨‍👨‍👧‍👦',
      '👨‍👨‍👦‍👦',
      '👨‍👨‍👧‍👧',
      '👩‍👦',
      '👩‍👧',
      '👩‍👧‍👦',
      '👩‍👦‍👦',
      '👩‍👧‍👧',
      '👨‍👦',
      '👨‍👧',
      '👨‍👧‍👦',
      '👨‍👦‍👦',
      '👨‍👧‍👧'
    ]
  },
  {
    name: '动植物',
    label: 'animal',
    value: [
      '🐶',
      '🐱',
      '🐭',
      '🐹',
      '🐰',
      '🦊',
      '🦝',
      '🐻',
      '🐼',
      '🦘',
      '🦡',
      '🐨',
      '🐯',
      '🦁',
      '🐮',
      '🐷',
      '🐽',
      '🐸',
      '🐵',
      '🙈',
      '🙉',
      '🙊',
      '🐒',
      '🐔',
      '🐧',
      '🐦',
      '🐤',
      '🐣',
      '🐥',
      '🦆',
      '🦢',
      '🦅',
      '🦉',
      '🦚',
      '🦜',
      '🦇',
      '🐺',
      '🐗',
      '🐴',
      '🦄',
      '🐝',
      '🐛',
      '🦋',
      '🐌',
      '🐚',
      '🐞',
      '🐜',
      '🦗',
      '🕷',
      '🕸',
      '🦂',
      '🦟',
      '🦠',
      '🐢',
      '🐍',
      '🦎',
      '🦖',
      '🦕',
      '🐙',
      '🦑',
      '🦐',
      '🦀',
      '🐡',
      '🐠',
      '🐟',
      '🐬',
      '🐳',
      '🐋',
      '🦈',
      '🐊',
      '🐅',
      '🐆',
      '🦓',
      '🦍',
      '🐘',
      '🦏',
      '🦛',
      '🐪',
      '🐫',
      '🦙',
      '🦒',
      '🐃',
      '🐂',
      '🐄',
      '🐎',
      '🐖',
      '🐏',
      '🐑',
      '🐐',
      '🦌',
      '🐕',
      '🐩',
      '🐈',
      '🐓',
      '🦃',
      '🕊',
      '🐇',
      '🐁',
      '🐀',
      '🐿',
      '🦔',
      '🐾',
      '🐉',
      '🐲',
      '🌵',
      '🎄',
      '🌲',
      '🌳',
      '🌴',
      '🌱',
      '🌿',
      '☘️',
      '🍀',
      '🎍',
      '🎋',
      '🍃',
      '🍂',
      '🍁',
      '🍄',
      '🌾',
      '💐',
      '🌷',
      '🌹',
      '🥀',
      '🌺',
      '🌸',
      '🌼',
      '🌻',
      '🌞',
      '🌝',
      '🌛',
      '🌜',
      '🌚',
      '🌕',
      '🌖',
      '🌗',
      '🌘',
      '🌑',
      '🌒',
      '🌓',
      '🌔',
      '🌙',
      '🌎',
      '🌍',
      '🌏',
      '💫',
      '⭐️',
      '🌟',
      '✨',
      '⚡️',
      '☄️',
      '💥',
      '🔥',
      '🌪',
      '🌈',
      '☀️',
      '🌤',
      '⛅️',
      '🌥',
      '☁️',
      '🌦',
      '🌧',
      '⛈',
      '🌩',
      '🌨',
      '❄️',
      '☃️',
      '⛄️',
      '🌬',
      '💨',
      '💧',
      '💦',
      '☔️',
      '☂️',
      '🌊',
      '🌫'
    ]
  },
  {
    name: '食物',
    label: 'food',
    value: [
      '🍏',
      '🍎',
      '🍐',
      '🍊',
      '🍋',
      '🍌',
      '🍉',
      '🍇',
      '🍓',
      '🍈',
      '🍒',
      '🍑',
      '🍍',
      '🥭',
      '🥥',
      '🥝',
      '🍅',
      '🍆',
      '🥑',
      '🥦',
      '🥒',
      '🥬',
      '🌶',
      '🌽',
      '🥕',
      '🥔',
      '🍠',
      '🥐',
      '🍞',
      '🥖',
      '🥨',
      '🥯',
      '🧀',
      '🥚',
      '🍳',
      '🥞',
      '🥓',
      '🥩',
      '🍗',
      '🍖',
      '🌭',
      '🍔',
      '🍟',
      '🍕',
      '🥪',
      '🥙',
      '🌮',
      '🌯',
      '🥗',
      '🥘',
      '🥫',
      '🍝',
      '🍜',
      '🍲',
      '🍛',
      '🍣',
      '🍱',
      '🥟',
      '🍤',
      '🍙',
      '🍚',
      '🍘',
      '🍥',
      '🥮',
      '🥠',
      '🍢',
      '🍡',
      '🍧',
      '🍨',
      '🍦',
      '🥧',
      '🍰',
      '🎂',
      '🍮',
      '🍭',
      '🍬',
      '🍫',
      '🍿',
      '🧂',
      '🍩',
      '🍪',
      '🌰',
      '🥜',
      '🍯',
      '🥛',
      '🍼',
      '☕️',
      '🍵',
      '🥤',
      '🍶',
      '🍺',
      '🍻',
      '🥂',
      '🍷',
      '🥃',
      '🍸',
      '🍹',
      '🍾',
      '🥄',
      '🍴',
      '🍽',
      '🥣',
      '🥡',
      '🥢'
    ]
  },
  {
    name: '运动',
    label: 'sport',
    value: [
      '⚽️',
      '🏀',
      '🏈',
      '⚾️',
      '🥎',
      '🏐',
      '🏉',
      '🎾',
      '🥏',
      '🎱',
      '🏓',
      '🏸',
      '🥅',
      '🏒',
      '🏑',
      '🥍',
      '🏏',
      '⛳️',
      '🏹',
      '🎣',
      '🥊',
      '🥋',
      '🎽',
      '⛸',
      '🥌',
      '🛷',
      '🛹',
      '🎿',
      '⛷',
      '🏂',
      '🏋️‍♀️',
      '🏋🏻‍♀️',
      '🏋🏼‍♀️',
      '🏋🏽‍♀️',
      '🏋🏾‍♀️',
      '🏋🏿‍♀️',
      '🏋️‍♂️',
      '🏋🏻‍♂️',
      '🏋🏼‍♂️',
      '🏋🏽‍♂️',
      '🏋🏾‍♂️',
      '🏋🏿‍♂️',
      '🤼‍♀️',
      '🤼‍♂️',
      '🤸‍♀️',
      '🤸🏻‍♀️',
      '🤸🏼‍♀️',
      '🤸🏽‍♀️',
      '🤸🏾‍♀️',
      '🤸🏿‍♀️',
      '🤸‍♂️',
      '🤸🏻‍♂️',
      '🤸🏼‍♂️',
      '🤸🏽‍♂️',
      '🤸🏾‍♂️',
      '🤸🏿‍♂️',
      '⛹️‍♀️',
      '⛹🏻‍♀️',
      '⛹🏼‍♀️',
      '⛹🏽‍♀️',
      '⛹🏾‍♀️',
      '⛹🏿‍♀️',
      '⛹️‍♂️',
      '⛹🏻‍♂️',
      '⛹🏼‍♂️',
      '⛹🏽‍♂️',
      '⛹🏾‍♂️',
      '⛹🏿‍♂️',
      '🤺',
      '🤾‍♀️',
      '🤾🏻‍♀️',
      '🤾🏼‍♀️',
      '🤾🏾‍♀️',
      '🤾🏾‍♀️',
      '🤾🏿‍♀️',
      '🤾‍♂️',
      '🤾🏻‍♂️',
      '🤾🏼‍♂️',
      '🤾🏽‍♂️',
      '🤾🏾‍♂️',
      '🤾🏿‍♂️',
      '🏌️‍♀️',
      '🏌🏻‍♀️',
      '🏌🏼‍♀️',
      '🏌🏽‍♀️',
      '🏌🏾‍♀️',
      '🏌🏿‍♀️',
      '🏌️‍♂️',
      '🏌🏻‍♂️',
      '🏌🏼‍♂️',
      '🏌🏽‍♂️',
      '🏌🏾‍♂️',
      '🏌🏿‍♂️',
      '🏇',
      '🏇🏻',
      '🏇🏼',
      '🏇🏽',
      '🏇🏾',
      '🏇🏿',
      '🧘‍♀️',
      '🧘🏻‍♀️',
      '🧘🏼‍♀️',
      '🧘🏽‍♀️',
      '🧘🏾‍♀️',
      '🧘🏿‍♀️',
      '🧘‍♂️',
      '🧘🏻‍♂️',
      '🧘🏼‍♂️',
      '🧘🏽‍♂️',
      '🧘🏾‍♂️',
      '🧘🏿‍♂️',
      '🏄‍♀️',
      '🏄🏻‍♀️',
      '🏄🏼‍♀️',
      '🏄🏽‍♀️',
      '🏄🏾‍♀️',
      '🏄🏿‍♀️',
      '🏄‍♂️',
      '🏄🏻‍♂️',
      '🏄🏼‍♂️',
      '🏄🏽‍♂️',
      '🏄🏾‍♂️',
      '🏄🏿‍♂️',
      '🏊‍♀️',
      '🏊🏻‍♀️',
      '🏊🏼‍♀️',
      '🏊🏽‍♀️',
      '🏊🏾‍♀️',
      '🏊🏿‍♀️',
      '🏊‍♂️',
      '🏊🏻‍♂️',
      '🏊🏼‍♂️',
      '🏊🏽‍♂️',
      '🏊🏾‍♂️',
      '🏊🏿‍♂️',
      '🤽‍♀️',
      '🤽🏻‍♀️',
      '🤽🏼‍♀️',
      '🤽🏽‍♀️',
      '🤽🏾‍♀️',
      '🤽🏿‍♀️',
      '🤽‍♂️',
      '🤽🏻‍♂️',
      '🤽🏼‍♂️',
      '🤽🏽‍♂️',
      '🤽🏾‍♂️',
      '🤽🏿‍♂️',
      '🚣‍♀️',
      '🚣🏻‍♀️',
      '🚣🏼‍♀️',
      '🚣🏽‍♀️',
      '🚣🏾‍♀️',
      '🚣🏿‍♀️',
      '🚣‍♂️',
      '🚣🏻‍♂️',
      '🚣🏼‍♂️',
      '🚣🏽‍♂️',
      '🚣🏾‍♂️',
      '🚣🏿‍♂️',
      '🧗‍♀️',
      '🧗🏻‍♀️',
      '🧗🏼‍♀️',
      '🧗🏽‍♀️',
      '🧗🏾‍♀️',
      '🧗🏿‍♀️',
      '🧗‍♂️',
      '🧗🏻‍♂️',
      '🧗🏼‍♂️',
      '🧗🏽‍♂️',
      '🧗🏾‍♂️',
      '🧗🏿‍♂️',
      '🚵‍♀️',
      '🚵🏻‍♀️',
      '🚵🏼‍♀️',
      '🚵🏽‍♀️',
      '🚵🏾‍♀️',
      '🚵🏿‍♀️',
      '🚵‍♂️',
      '🚵🏻‍♂️',
      '🚵🏼‍♂️',
      '🚵🏽‍♂️',
      '🚵🏾‍♂️',
      '🚵🏿‍♂️',
      '🚴‍♀️',
      '🚴🏻‍♀️',
      '🚴🏼‍♀️',
      '🚴🏽‍♀️',
      '🚴🏾‍♀️',
      '🚴🏿‍♀️',
      '🚴‍♂️',
      '🚴🏻‍♂️',
      '🚴🏼‍♂️',
      '🚴🏽‍♂️',
      '🚴🏾‍♂️',
      '🚴🏿‍♂️',
      '🏆',
      '🥇',
      '🥈',
      '🥉',
      '🏅',
      '🎖',
      '🏵',
      '🎗',
      '🎫',
      '🎟',
      '🎪',
      '🤹‍♀️',
      '🤹🏻‍♀️',
      '🤹🏼‍♀️',
      '🤹🏽‍♀️',
      '🤹🏾‍♀️',
      '🤹🏿‍♀️',
      '🤹‍♂️',
      '🤹🏻‍♂️',
      '🤹🏼‍♂️',
      '🤹🏽‍♂️',
      '🤹🏾‍♂️',
      '🤹🏿‍♂️',
      '🎭',
      '🎨',
      '🎬',
      '🎤',
      '🎧',
      '🎼',
      '🎹',
      '🥁',
      '🎷',
      '🎺',
      '🎸',
      '🎻',
      '🎲',
      '🧩',
      '♟',
      '🎯',
      '🎳',
      '🎮',
      '🎰'
    ]
  },
  {
    name: '交通',
    label: 'transport',
    value: [
      '🚗',
      '🚕',
      '🚙',
      '🚌',
      '🚎',
      '🏎',
      '🚓',
      '🚑',
      '🚒',
      '🚐',
      '🚚',
      '🚛',
      '🚜',
      '🛴',
      '🚲',
      '🛵',
      '🏍',
      '🚨',
      '🚔',
      '🚍',
      '🚘',
      '🚖',
      '🚡',
      '🚠',
      '🚟',
      '🚃',
      '🚋',
      '🚞',
      '🚝',
      '🚄',
      '🚅',
      '🚈',
      '🚂',
      '🚆',
      '🚇',
      '🚊',
      '🚉',
      '✈️',
      '🛫',
      '🛬',
      '🛩',
      '💺',
      '🛰',
      '🚀',
      '🛸',
      '🚁',
      '🛶',
      '⛵️',
      '🚤',
      '🛥',
      '🛳',
      '⛴',
      '🚢',
      '⚓️',
      '⛽️',
      '🚧',
      '🚦',
      '🚥',
      '🚏',
      '🗺',
      '🗿',
      '🗽',
      '🗼',
      '🏰',
      '🏯',
      '🏟',
      '🎡',
      '🎢',
      '🎠',
      '⛲️',
      '⛱',
      '🏖',
      '🏝',
      '🏜',
      '🌋',
      '⛰',
      '🏔',
      '🗻',
      '🏕',
      '⛺️',
      '🏠',
      '🏡',
      '🏘',
      '🏚',
      '🏗',
      '🏭',
      '🏢',
      '🏬',
      '🏣',
      '🏤',
      '🏥',
      '🏦',
      '🏨',
      '🏪',
      '🏫',
      '🏩',
      '💒',
      '🏛',
      '⛪️',
      '🕌',
      '🕍',
      '🕋',
      '⛩',
      '🛤',
      '🛣',
      '🗾',
      '🎑',
      '🏞',
      '🌅',
      '🌄',
      '🌠',
      '🎇',
      '🎆',
      '🌇',
      '🌆',
      '🏙',
      '🌃',
      '🌌',
      '🌉',
      '🌁'
    ]
  },
  {
    name: '物体',
    label: 'tool',
    value: [
      '⌚️',
      '📱',
      '📲',
      '💻',
      '⌨️',
      '🖥',
      '🖨',
      '🖱',
      '🖲',
      '🕹',
      '🗜',
      '💽',
      '💾',
      '💿',
      '📀',
      '📼',
      '📷',
      '📸',
      '📹',
      '🎥',
      '📽',
      '🎞',
      '📞',
      '☎️',
      '📟',
      '📠',
      '📺',
      '📻',
      '🎙',
      '🎚',
      '🎛',
      '⏱',
      '⏲',
      '⏰',
      '🕰',
      '⌛️',
      '⏳',
      '📡',
      '🔋',
      '🔌',
      '💡',
      '🔦',
      '🕯',
      '🗑',
      '🛢',
      '💸',
      '💵',
      '💴',
      '💶',
      '💷',
      '💰',
      '💳',
      '🧾',
      '💎',
      '⚖️',
      '🔧',
      '🔨',
      '⚒',
      '🛠',
      '⛏',
      '🔩',
      '⚙️',
      '⛓',
      '🔫',
      '💣',
      '🔪',
      '🗡',
      '⚔️',
      '🛡',
      '🚬',
      '⚰️',
      '⚱️',
      '🏺'
    ]
  },
  {
    name: '标志',
    label: 'mark',
    value: [
      '❤️',
      '🧡',
      '💛',
      '💚',
      '💙',
      '💜',
      '🖤',
      '💔',
      '❣️',
      '💕',
      '💞',
      '💓',
      '💗',
      '💖',
      '💘',
      '💝',
      '💟',
      '☮️',
      '✝️',
      '☪️',
      '🕉',
      '☸️',
      '✡️',
      '🔯',
      '🕎',
      '☯️',
      '☦️',
      '🛐',
      '⛎',
      '♈️',
      '♉️',
      '♊️',
      '♋️',
      '♌️',
      '♍️',
      '♎️',
      '♏️',
      '♐️',
      '♑️',
      '♒️',
      '♓️',
      '🆔',
      '⚛️',
      '🉑',
      '☢️',
      '☣️',
      '📴',
      '📳',
      '🈶',
      '🈚️',
      '🈸',
      '🈺',
      '🈷️',
      '✴️',
      '🆚',
      '💮',
      '🉐',
      '㊙️',
      '㊗️',
      '🈴',
      '🈵',
      '🈹',
      '🈲',
      '🅰️',
      '🅱️',
      '🆎',
      '🆑',
      '🅾️',
      '🆘',
      '❌',
      '⭕️',
      '🛑',
      '⛔️',
      '📛',
      '🚫',
      '💯',
      '💢',
      '♨️',
      '🚷',
      '🚯',
      '🚳',
      '🚱',
      '🔞',
      '📵',
      '🚭',
      '❗️',
      '❕',
      '❓',
      '❔',
      '‼️',
      '⁉️',
      '🔅',
      '🔆',
      '〽️',
      '⚠️',
      '🚸',
      '🔱',
      '⚜️',
      '🔰',
      '♻️',
      '✅',
      '🈯️',
      '💹',
      '❇️',
      '✳️',
      '❎',
      '🌐',
      '💠',
      'Ⓜ️',
      '🌀',
      '💤',
      '🏧',
      '🚾',
      '♿️',
      '🅿️',
      '🈳',
      '🈂️',
      '🛂',
      '🛃',
      '🛄',
      '🛅',
      '🚹',
      '🚺',
      '🚼',
      '🚻',
      '🚮',
      '🎦',
      '📶',
      '🈁',
      '🔣',
      'ℹ️',
      '🔤',
      '🔡',
      '🔠',
      '🆖',
      '🆗',
      '🆙',
      '🆒',
      '🆕',
      '🆓',
      '0️⃣',
      '1️⃣',
      '2️⃣',
      '3️⃣',
      '4️⃣',
      '5️⃣',
      '6️⃣',
      '7️⃣',
      '8️⃣',
      '9️⃣',
      '🔟',
      '🔢',
      '#️⃣',
      '*️⃣',
      '⏏️',
      '▶️',
      '⏸',
      '⏯',
      '⏹',
      '⏺',
      '⏭',
      '⏮',
      '⏩',
      '⏪',
      '⏫',
      '⏬',
      '◀️',
      '🔼',
      '🔽',
      '➡️',
      '⬅️',
      '⬆️',
      '⬇️',
      '↗️',
      '↘️',
      '↙️',
      '↖️',
      '↕️',
      '↔️',
      '↪️',
      '↩️',
      '⤴️',
      '⤵️',
      '🔀',
      '🔁',
      '🔂',
      '🔄',
      '🔃',
      '🎵',
      '🎶',
      '➕',
      '➖',
      '➗',
      '✖️',
      '♾',
      '💲',
      '💱',
      '™️',
      '©️',
      '®️',
      '〰️',
      '➰',
      '➿',
      '🔚',
      '🔙',
      '🔛',
      '🔝',
      '🔜',
      '✔️',
      '☑️',
      '🔘',
      '⚪️',
      '⚫️',
      '🔴',
      '🔵',
      '🔺',
      '🔻',
      '🔸',
      '🔹',
      '🔶',
      '🔷',
      '🔳',
      '🔲',
      '▪️',
      '▫️',
      '◾️',
      '◽️',
      '◼️',
      '◻️',
      '⬛️',
      '⬜️',
      '🔈',
      '🔇',
      '🔉',
      '🔊',
      '🔔',
      '🔕',
      '📣',
      '📢',
      '👁‍🗨',
      '💬',
      '💭',
      '🗯',
      '♠️',
      '♣️',
      '♥️',
      '♦️',
      '🃏',
      '🎴',
      '🀄️',
      '🕐',
      '🕑',
      '🕒',
      '🕓',
      '🕔',
      '🕕',
      '🕖',
      '🕗',
      '🕘',
      '🕙',
      '🕚',
      '🕛',
      '🕜',
      '🕝',
      '🕞',
      '🕟',
      '🕠',
      '🕡',
      '🕢',
      '🕣',
      '🕤',
      '🕥',
      '🕦',
      '🕧'
    ]
  }
]
