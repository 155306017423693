<template>
  <div class="pagoda-add-layout-v1">
    <layout-header
      content-title="编辑素材库"
      @backToList="handleBackToList"
    ></layout-header>
    <div class="pagoda-add-layout-v1__content">
      <div class="pagoda-add-layout-v1__title"></div>
      <div class="pagoda-add-layout-v1__main">
        <el-form
          class="el-form-reset-block"
          :rules="rules"
          :model="formData"
          ref="formRef"
        >
          <el-row class="pagoda-form-grid">
            <el-col v-bind="$pagoda.form.grid">
              <el-form-item label="标题" prop="title">
                <el-input
                  v-model="formData.title"
                  placeholder="请输入素材标题"
                  maxlength="30"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col v-bind="$pagoda.form.grid">
              <el-form-item label="选择分组" prop="group_id">
                <group-select
                  type="material"
                  v-model="formData.group_id"
                  @change="handleGroupId"
                  ref="group"
                ></group-select>
              </el-form-item>
            </el-col>
            <el-col v-bind="$pagoda.form.grid">
              <el-form-item label="类型" prop="type_id">
                <el-select
                  v-model="formData.type_id"
                  placeholder="请选择类型"
                  @change="changeType"
                >
                  <el-option
                    v-for="item in typeList"
                    :key="item.id"
                    :label="item.category_name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="pagoda-form-grid">
            <el-col :span="24">
              <el-form-item label="内容" prop="content">
                <template>
                  <el-input
                    id="editContent"
                    type="textarea"
                    v-model="formData.content"
                    placeholder="请输入内容"
                    maxlength="800"
                    :autosize="{ minRows: 3 }"
                  ></el-input>
                  <el-tabs v-model="emojiTab">
                    <el-tab-pane
                      v-for="(item, index) in emojiList"
                      :key="index"
                      :label="item.name"
                      :name="item.label"
                    >
                      <div class="emoji-box">
                        <div
                          v-for="(subItem, subIndex) in item.value"
                          :key="subIndex"
                          @click="insertText(subItem)"
                        >
                          <span>{{ subItem }}</span>
                        </div>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </template>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            class="pagoda-form-grid"
            v-show="showUplodImg"
            v-if="isImgTxtMix"
          >
            <el-col v-bind="$pagoda.form.grid">
              <el-form-item label="上传图片" prop="fileList">
                <upload-image
                  ref="uploadImg"
                  :id="formData.id"
                  :fileList.sync="formData.fileList"
                  @getSourceId="getSourceId"
                ></upload-image>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="pagoda-add-layout-v1__footer">
      <div class="pagoda-button-group">
        <el-button @click="handleCancel">取消</el-button>
        <el-button type="primary" v-debounce @click="handleSubmit">
          保存
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { emojiList } from '@/utils/emoji'
import { getPositionForTextArea } from '@/utils/common'
import uploadImage from '@/components/upload-image'
import groupSelect from '@/components/group-select'
import http from '@/services/api/reach'
import { rules } from './rules'
export default {
  data() {
    return {
      formData: {
        title: '',
        group_id: '',
        id: '',
        content: '',
        type_id: '',
        source_id: '',
        fileList: []
      },
      emojiList: emojiList,
      emojiTab: 'face',
      rules: {},
      typeList: this.$store.getters.materialTypeList || [],
      toolbar: {},
      showUplodImg: true,
      type: 1, // type为1是添加素材/话术，2是编辑
      timer: null // 定时器
    }
  },
  components: {
    uploadImage,
    groupSelect
  },
  computed: {
    isImgTxtMix() {
      let typeList = this.typeList
      let type_id = this.formData.type_id
      let typeInfo =
        typeList.find((ele) => {
          return ele.id == type_id
        }) || {}
      return typeInfo.category_name === '文本' ? false : true
    }
  },
  methods: {
    handleBackToList() {
      this.$router.push('/guideMaterialStore')
    },
    getMaterialType() {
      // console.log('getMaterialType', 111111111111);
      // const params = { type: 'material' }
      // http
      //   .getMaterialType(params)
      //   .then((res) => {
      //     console.log('编辑素材库',res)
      //     // 目前只需要公司话术这一个类型
      //     const list = res.data.slice(1, 2)
      //     this.$store.commit('MATERIAL_TYPE', list)
      //   })
      //   .catch((error) => {
      //     this.$message.error(error)
      //   })
    },
    getMaterialDetail(id) {
      http
        .getMaterialDetail(id)
        .then((res) => {
          this.formData.title = res.data.title || ''
          this.formData.group_id = res.data.group_id || ''
          this.$refs.group.value = res.data.group_id || ''
          this.formData.type_id = res.data.type_id || ''
          this.formData.content = res.data.content || ''
          this.formData.fileList = res.data.file || []
          for (var i = 0; i < res.data.file.length; i++) {
            this.$refs.uploadImg.sourceIdList.push(res.data.file[i])
          }
        })
        .catch(() => {})
    },
    editMaterial() {
      this.formData.type = 'material'
      const params = this.formData
      http
        .editMaterial(params)
        .then((res) => {
          this.$router.push('/guideMaterialStore')
        })
        .catch(() => {})
    },
    addMaterial() {
      this.formData.type = 'material'
      delete this.formData.id
      const params = this.formData
      http
        .addMaterial(params)
        .then((res) => {
          this.$router.push('/guideMaterialStore')
        })
        .catch(() => {})
    },
    insertText(value) {
      let position = document.getElementById('editContent')
      let pos = getPositionForTextArea(position)
      let y = position.value
      this.frontString = y.substring(0, pos)
      this.afterString = y.substring(pos, this.formData.content.length)
      this.formData.content = this.frontString + value + this.afterString
      //
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        // 插入表情等表情/手势,聚焦;
        this.$nextTick(() => {
          position.focus()
          position.selectionStart = position.selectionEnd = pos + value.length
        })
      }, 0)
    },
    handleSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          if (this.type === '1') {
            this.addMaterial()
          } else {
            this.editMaterial()
          }
        }
      })
    },
    changeType(category) {
      this.formData.type_id = category
    },
    handleGroupId(id) {
      this.formData.group_id = id
    },
    getSourceId(sourceIdList) {
      this.formData.source_id = ''
      sourceIdList.forEach((item) => {
        this.formData.source_id += item.source_id + ','
      })
      this.$refs.formRef.validate((fileList) => {
        if (fileList) {
          this.$refs.formRef.clearValidate(fileList)
        }
      })
    },
    handleCancel() {
      this.$router.go(-1)
    }
  },
  created() {
    this.queryObj = this.$route.query
    this.formData.id = this.queryObj.id
    this.type = this.queryObj.type
    if (this.type === '2') {
      this.getMaterialDetail(this.formData.id)
    }
    this.rules = rules(this)
    this.getMaterialType()
  }
}
</script>
<style lang="less">
.pagoda-add-layout-v1 {
  padding-left: 20px;
}
.emoji-box {
  white-space: break-spaces;
  display: flex;
  white-space: break-spaces;
  flex-wrap: wrap;
  div {
    font-size: 19px;
    width: 30px;
    text-align: center;
    margin: 10px 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
