<template>
  <el-select
    :value="value"
    clearable
    filterable
    remote
    placeholder="请选择分组"
    :remote-method="remoteMethod"
    :loading="loading"
    @clear="clearHandle"
    :disabled="abled"
    @change="handleChangeGroup"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.category_name"
      :value="item.id"
    >
      <span>{{ item.category_name }}</span>
    </el-option>
  </el-select>
</template>
<script>
import http from '@/services/api/reach'
export default {
  props: {
    abled: {
      type: Boolean,
      default: false
    },
    type: String
  },
  data() {
    return {
      value: '',
      options: [],
      loading: false
    }
  },
  mounted() {
    this.remoteMethod()
  },
  methods: {
    getData(value) {
      this.loading = true
      const params = {
        keyword: value,
        type: this.type
      }
      http.getMaterialGroup(params).then((res) => {
        this.loading = false
        this.options = res.data || []
      })
    },
    remoteMethod(query) {
      this.loading = true
      if (query !== '') {
        this.getData(query)
      } else {
        this.getData()
      }
    },
    handleChangeGroup(id) {
      this.options.forEach((item) => {
        if (item.id === id) {
          this.value = item.id
          this.$emit('change', id)
        }
      })
    },
    clearHandle() {
      this.value = ''
      this.getData()
    }
  }
}
</script>
