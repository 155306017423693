<template>
  <div>
    <el-upload
      :action="''"
      ref="upload"
      list-type="picture-card"
      :limit="limit"
      :before-upload="beforeUpload"
      :http-request="uploadImg"
      :on-exceed="handleExceed"
      :accept="fileTypeList"
      :file-list="fileArray"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file, fileArray)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { timestampToTime } from '../utils/date'
import http from '@/services/api/common'
import reach from '@/services/api/reach'

export default {
  props: {
    fileList: Array
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      limit: 3,
      data: {},
      id: '',
      source_id: '',
      sourceIdList: [],
      /* 文件类型限制 */
      fileTypeList: '.jpg,.png,.JPG,.PNG'
    }
  },
  computed: {
    fileArray() {
      return this.fileList || []
    }
  },
  methods: {
    handleRemove(file, fileArray) {
      this.sourceIdList.forEach((item, index) => {
        if (item.uid === file.uid) {
          this.sourceIdList.splice(index, 1)
          this.$emit('getSourceId', this.sourceIdList)
        }
      })
      if (file.id !== '' && file.id !== undefined) {
        const params = {
          source_id: file.source_id,
          id: file.id
        }
        reach
          .deleteImage(params)
          .then((res) => {
            this.$refs.upload.handleRemove(file)
          })
          .catch(() => {})
      } else {
        this.$refs.upload.handleRemove(file)
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    beforeUpload(file) {
      // 判断文件大小、格式
      const isRightName = file.name.length <= Infinity
      const isLT8M = file.size / 1024 / 1024 <= 2
      const fileNameArray = file.name.split('.')
      const fileSuffix = `.${fileNameArray[fileNameArray.length - 1]}` // 文件名后缀
      const isSameFileName =
        this.fileList && this.fileList.find((i) => i.resourceName === file.name) // 文件名称是否重复
      // 根据文件名后缀判断文件格式是否正确
      if (
        this.fileTypeList.length > 0 &&
        !this.fileTypeList.includes(fileSuffix)
      ) {
        if (this.typeErrorMsgIdx) {
          this.$message.error(this.typeErrorMsg[this.typeErrorMsgIdx])
        } else {
          this.$message.error('文件格式不正确')
        }
        return false
      }
      if (!isRightName) {
        this.$message.error('文件名称不能超过30个字（含后缀名）')
        return false
      }
      if (isSameFileName) {
        this.$message.error('上传文件名称不可相同，请修改文件名后重新上传')
        return false
      }
      if (!isLT8M) {
        this.$message.error('文件大小不能超过2M')
        return false
      }
      const time = timestampToTime()
      var formData = new FormData()
      formData.append('file', file)
      formData.append('uid', file.uid)
      formData.append('source_name', file.name)
      formData.append('source_type', '1')
      formData.append('validity_start_time', time)
      formData.append('validity_end_time', time)
      this.data = formData
    },
    uploadImg(file) {
      const params = this.data
      let loading = this.$loading({
        fullscreen: true,
        lock: true
      })
      http
        .uploadImage(params)
        .then((res) => {
          loading.close()
          this.sourceIdList.push({
            uid: file.file.uid,
            source_id: res.data.source_id,
            source_name: res.data.source_name,
            file_size: res.data.file_size,
            md5: res.data.md5
          })
          this.$emit('getSourceId', this.sourceIdList)
        })
        .catch(() => {
          loading.close()
        })
    },
    handleExceed() {
      this.$message.error(`最多只能上传${this.limit}个文件`)
    }
  }
}
</script>
