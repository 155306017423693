export function rules(v) {
  return {
    title: {
      required: true,
      message: '请输入标题',
      trigger: 'blur'
    },
    category_id: {
      required: true,
      message: '请选择类型',
      trigger: 'change'
    },
    content: [
      {
        required: true,
        message: '请输入内容',
        trigger: 'blur'
      }
    ],
    fileList: [
      {
        required: true,
        validator: (rule, value, callback) => {
          if (v.formData.fileList.length === 0 && v.formData.source_id === '') {
            return callback(new Error('请上传图片'))
          }
          return callback()
        },
        trigger: 'blur'
      }
    ],
    group_id: {
      required: true,
      message: '请选择分组',
      trigger: 'change'
    }
  }
}
